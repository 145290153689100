/* radiobuttons */
$(document).on('change', '.__radiobuttons input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .closest('.__radiobuttons')
            .find('.input_wrap > label')
            .removeClass('__checked');
        $(this)
            .parent()
            .addClass('__checked');
    }
});
$('.__radiobuttons input:checked')
    .parent()
    .addClass('__checked');

/* checkboxes */
$(document).on('change', '.__checkbox input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkbox input:checked')
    .parent()
    .addClass('__checked');

/* focus styling */
$(document).ready(function() {
    $('.field_wrap.__radiobuttons input').focus(function() {
        $(this)
            .closest('label')
            .addClass('__focused');
    });
    $('.field_wrap.__radiobuttons input').blur(function() {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
    $('.field_wrap.__checkbox input').focus(function() {
        $(this)
            .closest('label')
            .addClass('__focused');
    });
    $('.field_wrap.__checkbox input').blur(function() {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
});

$('.__select select').each(function() {
    var selectId = $(this).parent();
    $(this).select2({
        containerCssClass: 'select2-css',
        minimumResultsForSearch: Infinity,
        dropdownParent: selectId
    })
});

flatpickr('.__datepicker input:not(.flatpickr-input):not(.input)',{
    altFormat: "d/m/y",
    dateFormat: "d-m-Y",
    altInput: true,
});

/* add focus class to inputs */
//when focused on input, apply focus class
$('.__labelshrink input, .__labelshrink textarea').focus(function() {
    var thisLabel = $(this)
        .parent()
        .prev();

    thisLabel.addClass('__focus');
});
//when taking focus off an input, if there is no value in the input, apply focus class
$('.__labelshrink input, .__labelshrink textarea').focusout(function() {
    var thisLabel = $(this)
        .parent()
        .prev();
    if ($(this).val() == '') {
        thisLabel.removeClass('__focus');
    }
});
// checking if field has value when page loads - for submit errors
$('.__labelshrink input, .__labelshrink textarea').each(function() {
    var thisLabel = $(this)
        .parent()
        .prev();
    if ($(this).val()) {
        thisLabel.addClass('__focus');
    }
});
