$('.Tabs').each(function(i, tab) {
    var tabTrigger = '.TabTrigger';
    var tabContent = '.TabContent';
    var findTabTrigger = $(this).find(tabTrigger);
    var findTabContent = $(this).find(tabContent);

    findTabTrigger.attr('aria-selected', false);
    findTabContent.attr('aria-expanded', false);

    findTabTrigger.eq(0).addClass('__active');
    findTabTrigger.eq(0).attr('aria-selected', true);
    findTabContent.eq(0).addClass('__active');
    findTabContent.eq(0).attr('aria-expanded', true);

    findTabTrigger.click(function(e) {
        e.preventDefault();
        var dataSet = $(this).data('set');

        $(this)
            .siblings(tabTrigger + '.__active')
            .attr('aria-selected', false);
        $(this)
            .siblings(tabTrigger + '.__active')
            .removeClass('__active');
        $(this).attr('aria-selected', true);
        $(this).addClass('__active');

        $(this)
            .parents('.tabpanel-head')
            .siblings('.tabpanel-content')
            .children(tabContent + '.__active')
            .removeClass('__active');
        $(this)
            .parents('.tabpanel-head')
            .siblings('.tabpanel-content')
            .children(tabContent + '[data-set="' + dataSet + '"]')
            .addClass('__active');
        $(this)
            .parents('.tabpanel-head')
            .siblings('.tabpanel-content')
            .children(tabContent + '[data-set="' + dataSet + '"]')
            .attr('aria-expanded', true);
    });
});
