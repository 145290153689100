// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Load BS
require('bootstrap');

//Load Libraries
require('flatpickr/dist/flatpickr.min');
require('magnific-popup/dist/jquery.magnific-popup.min');
require('objectFitPolyfill/dist/objectFitPolyfill.min');
require('select2/dist/js/select2.full.min');
require('slick-slider/slick/slick.min');
require('chart.js/dist/Chart.min');

//Load Modules
require('./modules/accordion');
require('./modules/animatein');
require('./modules/bannerVideo');
require('./modules/formInputs');
require('./modules/localVideoBlock');
require('./modules/menu');
require('./modules/modal');
require('./modules/scrollTo');
require('./modules/searchToggle');
require('./modules/shareSocial');
require('./modules/sideMenu');
require('./modules/tabs');

// DROPDOWN
    // Append window for exit interaction
    $('body').append('<div class="dropdownWindow" style="display: none"></div>');
    // On button click open/close dropdown
    $('.dropdownButton').on('click', function(){
        var dropdownWrapper = $(this).parents('.dropdown');
        if(dropdownWrapper.hasClass('__active')) {
            dropdownWrapper.removeClass('__active');
            $(this).attr('aria-expanded', false)
            $(this).siblings('.dropdownContent').hide();
            $('.dropdownWindow').hide();
        } else {
            dropdownWrapper.addClass('__active');
            $(this).attr('aria-expanded', true)
            $(this).siblings('.dropdownContent').show();
            $('.dropdownWindow').show();
        }
    });
    // On click out close dropdown
    $('.dropdownWindow').on('click', function(){
        var dropdownWrapper = $(this).parents('.dropdown');
        $('.dropdown').removeClass('__active');
        $('.dropdownButton').attr('aria-expanded', false)
        $('.dropdownContent').hide();
        $('.dropdownWindow').hide();
    });
    // On press escape close dropdown
    $(document).keydown(function(e) {
        if (e.keyCode == 27) { // Escape key
            var dropdownWrapper = $(this).parents('.dropdown');
            $('.dropdown').removeClass('__active');
            $('.dropdownButton').attr('aria-expanded', false)
            $('.dropdownContent').hide();
            $('.dropdownWindow').hide();
        }
    });

// SELECT ALL
    // Select all on button click
    $('.selectAll').click(function(){
        if ($(this).hasClass('__active')) {
            $(this).parents('.input_wrap').find('input[type="checkbox"]').prop('checked', false).trigger('change');
            $(this).text('Select all').removeClass('__active');
        } else {
            $(this).text('Unselect all').addClass('__active');
            $(this).parents('.input_wrap').find('input[type="checkbox"]').prop('checked', true).trigger('change');
        }
    });
    // Uncheck select all button on deselect of checkbox
    $('.selectItem input[type=checkbox]').click(function(){
        if (!($(this).checked)) {
            $(this).siblings('.selectAll').text('Select all').removeClass('__active');
        }
    });
    // Update dropdown button text on select
    $('.selectItem input[type=checkbox]').change(function(){
        var count = $(this).parents('.dropdownContent').find('input:checked').length;
        var button = $(this).parents('.dropdown').find('.dropdownButton')
        var buttonPlaceholder = $(this).parents('.dropdown').find('.dropdownButton').attr('data-placeholder');
        var buttonUnits = $(this).parents('.dropdown').find('.dropdownButton').attr('data-unit');
        if (count > 0) {
            button.text(count + ' ' + buttonUnits + ' selected');
        } else {
            button.text(buttonPlaceholder);
        }
    });
